import { writable } from 'svelte/store';

type NotificationType = 'offline' | 'upgrade' | 'general' | 'forbidden';

// TODO: Add timer to periodically fetch latest notifications from server.
// TODO: Add property to distinguish between effermeral and persistent notifications.
// TODO: Add capability to persist certain notifications to database (eg. AHK parse failures)

export class Notification {

    uuid: string;
    creation_date: Date;
    type: NotificationType;
    icon: string;
    header: string;
    text: string;
    action_label?: string;
    action_callback?: () => void;

    constructor(message_type: NotificationType, icon: string, header: string, text: string, action_label?: string, action_callback?: () => void) {
        this.uuid = crypto.randomUUID();
        this.creation_date = new Date();
        this.type = message_type;
        this.icon = icon;
        this.header = header;
        this.text = text;
        this.action_label = action_label;
        this.action_callback = action_callback;
    }
}

export class NotificationState {

    notifications: Notification[];

    constructor() {
        this.notifications = [];
    }

    private add_notification(notification: Notification) {
        this.notifications.push(notification);
    }

    notify_offline() {

        let type: NotificationType = 'offline';
        let icon = 
            // outline/wifi (plus stroke line)
            `
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.288 15.038a5.25 5.25 0 017.424 0M5.106 11.856c3.807-3.808 9.98-3.808 13.788 0 M1.924 8.674c5.565-5.565 14.587-5.565 20.152 0M12.53 18.22l-.53.53-.53-.53a.75.75 0 011.06 0z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3 3l8.735 8.735m0 0a.374.374 0 11.53.53m-.53-.53l.53.53m0 0L19 19" />
                </svg>
            `;
        let header = "Offline";
        let text = "You are currently offline. Features may be temporarily disabled.";
        
        let notification = new Notification(type, icon, header, text);
        this.add_notification(notification);
    }

    notify_upgrade(action_label: string, action_callback: () => void) {

        let type: NotificationType = 'upgrade';
        let icon = 
            // outline/cloud-arrow-down
            `
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9.75v6.75m0 0l-3-3m3 3l3-3m-8.25 6a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
                </svg>
            `;
        let header = "Update available";
        let text = "Approve below to install changes.";
        
        let notification = new Notification(type, icon, header, text, action_label, action_callback);
        this.add_notification(notification);
    }

    notify_forbidden() {

        let type: NotificationType = 'forbidden';
        let icon = 
            // outline/minus-circle
            `
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            `;
        let header = "Forbidden Access";
        let text = "Please make sure you are logged in and have the appropriate access rights.";
        
        let notification = new Notification(type, icon, header, text);
        this.add_notification(notification);
    }

    notify_message(header: string, text: string, action_label?: string, action_callback?: () => void) {

        let type: NotificationType = 'general';
        let icon = 
            // outline/information-circle
            `
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                </svg>
            `;
        
        let notification = new Notification(type, icon, header, text, action_label, action_callback);
        this.add_notification(notification);
    }

    dismiss(uuid: string) {

        this.notifications = this.notifications.filter(item => item.uuid !== uuid);
    }
}

function build_notification_store() {
    let store: NotificationState = new NotificationState();
    const { subscribe, update } = writable(store);

    return {
        subscribe,
        state: () => store,
        notify_offline: () => 
            update((notification_state: NotificationState) => { 

                notification_state.notify_offline();
                return notification_state; 
            }),
        notify_upgrade_available: (action_callback: () => void) => 
            update((notification_state: NotificationState) => { 
                
                notification_state.notify_upgrade("Upgrade", action_callback);
                return notification_state; 
            }),
        notify_forbidden: () => 
            update((notification_state: NotificationState) => { 
                
                notification_state.notify_forbidden();
                return notification_state; 
            }),
        notify_message: (header: string, message: string, action_label?: string, action_callback?: () => void) => 
            update((notification_state: NotificationState) => { 
                
                notification_state.notify_message(header, message, action_label, action_callback);
                return notification_state; 
            }),
        dismiss: (uuid: string) => 
            update((notification_state: NotificationState) => { 
                    
                notification_state.dismiss(uuid);
                return notification_state; 
            }),
    };       
};

export const notification_store = build_notification_store();